(function ($) {
  var tabs = UneekSso.UserSessions.getTabsSelector();
  var inactiveTabs = UneekSso.UserSessions.getInactiveTabsSelector();

  function enableTabInputs(tab, enable) {
    $(tab.hash).find("input, textarea, select").prop("disabled", !enable);
    $(tab).trigger("formInputsEnabled.uneekSso", [enable]);
  }

  $(document).on("show.bs.tab", tabs, function () {
    enableTabInputs(this, true);
  });
  $(document).on("hide.bs.tab", tabs, function () {
    enableTabInputs(this, false);
  });

  $(document).on("loginFormLoaded.uneekSso", function () {
    $(inactiveTabs).each(function () {
      enableTabInputs(this, false);
    });
  });
})(jQuery);
