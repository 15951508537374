$.fn.imageField = function (options) {
  if (!this.length)
    return;

  try {
    import("dropzone").then(({ default: Dropzone }) => {
      if (!Dropzone.isBrowserSupported)
        return;

      options = $.extend({
        file: "input[type=file]",
        preview: "img"
      }, options);

      this.each(function () {
        var $this = $(this);
        var file = $this.find(options.file);
        var label = $this.find(options.label || "label[for=" + file.attr("id") + "]");
        var preview = $this.find(options.preview);

        function setFiles(files) {
          file.prop("files", files);
          var fileReader = new FileReader();
          fileReader.onload = function () {
            preview.prop("src", fileReader.result);
          };
          fileReader.filename = files[0].name;
          fileReader.readAsDataURL(files[0]);
        }

        file.hide();
        if (file.prop("disabled"))
          return

        var dropzone = $this.dropzone({
          url: ".",
          autoProcessQueue: false,
          autoQueue: false,
          acceptedFiles: file.accept || "image/*",
          previewsContainer: false,
          maxFiles: 1,
          clickable: preview[0],
          hiddenInputContainer: this,
        });
        dropzone.on("drop", function(e) {
          setFiles(e.originalEvent.dataTransfer.files)
        })

        $this.on("change", function (e) {
          if (!$(e.target).is(".dz-hidden-input"))
            return;
          setFiles(e.target.files);
        });

        label.click(function (e) {
          $this.find(".dz-hidden-input").click();

          e.preventDefault();
          return false;
        });
      });
    });
  } catch (e) {
    console.warn("Promise is not supported")
  }
};

$(document).on("turbolinks:load", function () {
  $(".image-field").imageField();
});
