function forEachDatatable(f) {
  let $datatables = $("table[data-datatable-ajax--url]")
  if (!$datatables.length)
    return

  import("./datatables").then((r) => {
    $datatables.each(function () {
      f($(this), r)
    })
  })
}

$(document).on("turbolinks:load", () => {
  forEachDatatable(($elem, { EditableDatatable }) => {
    new EditableDatatable($elem).init()
  })
})

$(document).on("turbolinks:before-cache", function () {
  forEachDatatable(($elem, { EditableDatatable }) => {
    $elem.data(EditableDatatable.DATA_PROPERTY_NAME)?.destroy()
  })
})
