UneekSso.UserSessions = $.extend({
  onLoad: function () {
    if (UneekSso.UserSessions.modalSelector) {
      $(document).on("show.bs.modal", UneekSso.UserSessions.modalSelector, UneekSso.UserSessions._showModal);
      $(document).on("hide.bs.modal", UneekSso.UserSessions.modalSelector, UneekSso.UserSessions._hideModal);
      $(document).on("submit", UneekSso.UserSessions.modalSelector, UneekSso.UserSessions._submitModal);
    }
  },

  getTabsSelector: function () {
    return UneekSso.UserSessions.modalSelector + " .modal-header .nav-link";
  },

  getInactiveTabsSelector: function () {
    return UneekSso.UserSessions.getTabsSelector() + ":not(.active)";
  },

  _getModalContent: function (modal) {
    return modal.find(".modal-content");
  }
}, UneekSso.UserSessions);

UneekSso.onLoad(UneekSso.UserSessions.onLoad);
