UneekSso._loadFunction = UneekSso._loadFunction || function (f) {
  if (typeof Turbolinks === "undefined") {
    if (document.readyState !== "loading") {
      window.setTimeout(f)
    } else {
      window.addEventListener("load", f)
    }
  } else {
    window.addEventListener("turbolinks:load", f);
  }
};

UneekSso.UserSessions.getCasBaseLoginUrl = UneekSso.UserSessions.getCasBaseLoginUrl || function () {
  return UneekSso.UserSessions.casBaseLoginUrl; // user defined
};

UneekSso.UserSessions.getCasLoginUrl = UneekSso.UserSessions.getCasLoginUrl || function () {
  var baseUrl = UneekSso.UserSessions.getCasBaseLoginUrl();

  var params = {};
  this._addThemeToParams(params);
  this._addTemporaryTokenToParams(params);

  var connectChar = (baseUrl.indexOf("?") > 0) ? "&" : "?";
  return baseUrl + connectChar + new URLSearchParams(params).toString();
};

UneekSso.UserSessions._addThemeToParams = UneekSso.UserSessions._addThemeToParams || function (params) {
  if (!UneekSso.UserSessions.theme)
    return params;
  params.theme = UneekSso.UserSessions.theme;
  return params;
};

UneekSso.UserSessions._addTemporaryTokenToParams = UneekSso.UserSessions._addTemporaryTokenToParams || function (params) {
  if (!UneekSso.UserSessions.temporaryTokenInParams)
    return params;
  var locationParams = new URLSearchParams(window.location.search);
  if (!locationParams.has("user[login]") && !locationParams.has("user[password]"))
    return params;
  params["user[login]"] = locationParams.get("user[login]");
  params["user[password]"] = locationParams.get("user[password]");
  return params;
};

UneekSso.UserSessions.isConnecting = UneekSso.UserSessions.isConnecting || function () {
  return !!this._connecting;
};

UneekSso.UserSessions.manageRenderServiceXhr = UneekSso.UserSessions.manageRenderServiceXhr || function (xhr, f, formOpened) {
  if (xhr.status === 201) {
    // already connected
    UneekSso.UserSessions.renderServiceXhr(xhr, formOpened); // TODO if reload cable: on any XHR request here
  } else {
    f(xhr);
  }
};

UneekSso.UserSessions.renderServiceXhr = UneekSso.UserSessions.renderServiceXhr || function (xhr, formOpened) {
  UneekSso.UserSessions.loginSucceeds(xhr);
};

UneekSso.UserSessions.loginSucceeds = UneekSso.UserSessions.loginSucceeds || function (xhr) {
  // TODO data contains the page content; it is stupid to redirect to it... (see replace())
  if (typeof Turbolinks === "undefined")
    location.reload();
  else
    Turbolinks.visit(location); // TODO reload cable: and here
};

UneekSso.UserSessions.loginFailed = UneekSso.UserSessions.loginFailed || function (xhr) {
  if ((xhr.status === 0) && (xhr.readyState === 0)) {
    // bug with Firefox: impossible to follow redirection
    UneekSso.UserSessions._loginFailedFirefoxRedirection(xhr);
  } else if ((xhr.status >= 400) && (xhr.status < 500)) {
    // user error
    UneekSso.UserSessions._loginFailedUserError(xhr);
  } else {
    // other error, probably server
    UneekSso.UserSessions._loginFailedServerError(xhr);
  }
};

UneekSso.UserSessions._loginFailedFirefoxRedirection = UneekSso.UserSessions.loginFailedFirefoxRedirection || function (xhr) {
  // we should be connected to SSO server, therefore we redirect manually to service URL
  UneekSso.UserSessions._redirectToCasLogin();
};

UneekSso.UserSessions._loginFailedUserError = UneekSso.UserSessions._loginFailedUserError || function (xhr) {
  console.warn(xhr.responseText);
  if (!UneekSso.dev)
    UneekSso.UserSessions._redirectToCasLogin();
};

UneekSso.UserSessions._loginFailedServerError = UneekSso.UserSessions._loginFailedServerError || function (xhr) {
  console.error(xhr.responseText);
  if (!UneekSso.dev)
    UneekSso.UserSessions._redirectToCasLogin();
};

UneekSso.UserSessions._redirectToCasLogin = UneekSso.UserSessions._redirectToCasLogin || function () {
  window.location = UneekSso.UserSessions.getCasLoginUrl();
};

UneekSso.UserSessions.getCasLogoutUrl = UneekSso.UserSessions.getCasLogoutUrl || function () {
  return UneekSso.UserSessions.casLogoutUrl; // user defined
};

UneekSso.UserSessions.logoutSucceeded = UneekSso.UserSessions.logoutSucceeded || function (xhr) {
  // user redefined
};

UneekSso.UserSessions.logoutFailed = UneekSso.UserSessions.logoutFailed || function (xhr) {
  window.location = UneekSso.UserSessions.getCasLogoutUrl();
};

UneekSso.onLoad(function () {
  if (typeof Turbolinks === "undefined")
    return;
  // logout with Turbolinks
  document.addEventListener("turbolinks:request-start", function (event) {
    if (!UneekSso.UserSessions.isLogoutUrl || !UneekSso.UserSessions.isLogoutUrl(event.data.url))
      return;
    event.data.xhr.withCredentials = true;
  });
});

UneekSso.load();
