/* Automatically show a tab if the window location hash refers to it */

$(document).on("turbolinks:load", function (event) {
  if (window.location.hash) {
    $("a.tab-auto-location[role=tab][href=\\" + window.location.hash + "]").tab("show");
  } else {
    var defaultTab = $("a.tab-auto-location.tab-auto-location-default[role=tab]");
    defaultTab.data("bootstrap-tab-auto-location", true); // assert that we were on the default tag on loading
    defaultTab.tab("show");
  }
});


/* Automatically set the window location hash when clicking on a tab */

$(document).on("show.bs.tab", "a.tab-auto-location[role=tab]", function (event) {
  var $target = $(event.target);
  var href;
  if ($target.hasClass("tab-auto-location-default") && $target.data("bootstrap-tab-auto-location")) {
    href = "";
  } else {
    href = $target.attr("href");
  }

  if (href !== window.location.hash) // to prevent to add a new history entry on reload
    Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(href, Turbolinks.uuid()); // NOTE: Turbolinks private method, undocumented: may not work in future versions...
});
