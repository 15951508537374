import Create from "./create"

export default class Session {
  static create(url, options = {}) {
    return Create.create(url, options).then(token => new Session(token))
  }

  constructor(token) {
    this.token = token
    this.valid = true
  }

  ajax(options = {}) {
    const self = this
    let deferred = $.Deferred()
    const request = $.ajax(this.ajaxOptions(options))
    request.then(function () {
      deferred.resolveWith(request, arguments)
    }, function (xhr) {
      if (xhr.status === 401) {
        self.valid = false
      }
      deferred.rejectWith(request, arguments)
    })
    return deferred
  }

  ajaxOptions(options = {}) {
    return $.extend({
      headers: $.extend({
        Authorization: `Bearer ${this.token}`,
      }, options.headers),
    }, options)
  }
}
