import "./user_sessions"

import Autocomplete from "./autocomplete"
import JWT from "./jwt"

UneekSso._loadFunction = UneekSso._loadFunction || function (f) {
  if (typeof Turbolinks === "undefined")
    $(f);
  else
    $(document).on("turbolinks:load", f);
};

UneekSso.Autocomplete = Autocomplete
UneekSso.JWT = JWT
