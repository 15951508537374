function create(service, options = {}) {
  return $.ajax(this._createOptions(service, options))
}

function _createOptions(service, options = {}) {
  return {
    method: "POST",
    url: `${UneekSso.url}/jwt`,
    data: {
      service: service,
    },
    xhrFields: {
      withCredentials: true,
    },
  }
}

export default {
  create,
  _createOptions,
}
