import consumer from "./consumer"

(function() {
  this.App || (this.App = {})

  this.App.notification = consumer.then((consumer) => {
    return consumer.subscriptions.create("NotificationChannel", {
      received: function (data) {
        for (var k in data) {
          switch(k) {
            case "notification":
              this._sendNotification(data[k])
              break
          }
        }
      },

      updateNotificationStatus: function (id, status, reason) {
        if (typeof id === "undefined")
          return
        this.perform("update_notification_status", {
          id: id,
          status: status,
          reason: reason
        })
      },

      _sendNotification: function (params) {
        return this._withGrantedNotifications(() => {
          this._constructNotification(params)
        }, (reason) => {
          return this._rejectNotification(params, reason)
        })
      },

      // TODO "default" is now a valid result and so we can lose some notifications
      _withGrantedNotifications: function (resolve, reject) {
        if (!("Notification" in window)) {
          return reject("unsupported")
        } else if (Notification.permission === "granted") {
          return resolve()
        } else if (Notification.permission === "denied") {
          return reject("denied")
        } else {
          Notification.requestPermission((permission) => {
            if (!("permission" in Notification))
              Notification.permission = permission

            if (permission === "granted")
              return resolve()
            else
              return reject("denied")
          })
        }
      },

      _constructNotification: function (params) {
        var notification = new Notification(params.title, params)
        notification.onshow = () => {
          this.updateNotificationStatus(params.id, "received")
        }
        notification.onclick = () => {
          this.updateNotificationStatus(params.id, "read")
          if (params.link) {
            window.open(params.link)
          }
        }
        notification.onerror = () => {
          this._rejectNotification(params, "client_error")
        }
        return notification
      },

      _rejectNotification: function (params, reason) {
        this.updateNotificationStatus(params.id, "rejected", reason)
      }
    })
  })
}).call(UneekSso)
