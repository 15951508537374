/*
 * Turbolinks considers that a request with a status out of the 200 range is an error.
 * It tries to reload the page and fails completly.
 * The problem is that the user login form returns a 401 status but it is not an "error".
 * This monkey patch bypasses Turbolinks behavior and considers a request to be a success if it has the header "Turbolinks-Success".
 */

// copied from turbolinks/http_request.coffee
Turbolinks.HttpRequest.prototype.requestLoaded = function () {
  this.endRequest(function () {
    if (((this.xhr.status >= 200) && (this.xhr.status < 300)) || this.xhr.getResponseHeader("Turbolinks-Success")) { // change here
      this.delegate.requestCompletedWithResponse(this.xhr.responseText, this.xhr.getResponseHeader("Turbolinks-Location"));
    } else {
      this.failed = true;
      this.delegate.requestFailedWithStatusCode(this.xhr.status, this.xhr.responseText);
    }
  });
};
