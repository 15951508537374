function users(options = {}) {
  return $.ajax(this._usersAjaxOptions(options))
}

function _usersAjaxOptions(options = {}) {
  return {
    method: "GET",
    url: `${UneekSso.url}/users/autocomplete`,
    data: (params) => {
      params.service = UneekSso.service
      return params;
    },
    dataType: "json",
    processResults: (data) => {
      return {
        results: data,
        pagination: {
          more: (data.length >= 10), // AutocompleteController::AUTOCOMPLETE_MAX_LIMIT
        },
      }
    },
    crossDomain: false,
    xhrFields: {
      withCredentials: true,
    },
  }
}

export default {
  users,
  _usersAjaxOptions,
}
