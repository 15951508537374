UneekSso.UserSessions = $.extend({
  modalSelector: null, // user defined
  defaultAjaxOptions: {},
  showModalAjaxOptions: {},
  submitModalAjaxOptions: {},
  logoutXhrAjaxOptions: {},

  logoutXhr: function () {
    return this._sendAjax("logoutXhr", {
      type: "GET",
      url: UneekSso.UserSessions.getCasLogoutUrl(),
      success: function (data, textStatus, xhr) {
        UneekSso.UserSessions.logoutSucceeded(xhr);
      },
      error: UneekSso.UserSessions.logoutFailed,
    })
  },

  _sendAjax: function (action, ajaxOptions) {
    return $.ajax($.extend({
      dataType: "html",
      crossDomain: true,
      xhrFields: {
        withCredentials: true,
      },
      beforeSend: (xhr) => {
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest")
      },
    }, UneekSso.UserSessions.defaultAjaxOptions, ajaxOptions, UneekSso.UserSessions[action + "AjaxOptions"] || {}));
  },

  // redefined
  _getModalContent: function (modal) {
    return null;
  },

  _loadModalContent: function (data) {
    var $modal = $(UneekSso.UserSessions.modalSelector);
    var modalContent = UneekSso.UserSessions._getModalContent($modal);
    if (!UneekSso.UserSessions._oldModalContent)
      UneekSso.UserSessions._oldModalContent = modalContent.html();
    modalContent.html(data);
    $modal.trigger("loginFormLoaded.uneekSso");
  },

  _restoreModalContent: function () {
    var $modal = $(UneekSso.UserSessions.modalSelector);
    if (UneekSso.UserSessions._oldModalContent) {
      var modalContent = UneekSso.UserSessions._getModalContent($modal);
      modalContent.html(UneekSso.UserSessions._oldModalContent);
      UneekSso.UserSessions._oldModalContent = null;
    }
    $modal.trigger("loginFormUnloaded.uneekSso");
  },

  _showModal: function (e) {
    UneekSso.UserSessions._sendAjax("showModal", {
      type: "GET",
      url: UneekSso.UserSessions.getCasLoginUrl(),
      success: function (data, textStatus, xhr) {
        UneekSso.UserSessions.manageRenderServiceXhr(xhr, function () {
          UneekSso.UserSessions._loadModalContent(xhr.responseText);
        }, true);
      },
      error: UneekSso.UserSessions._loginXhrError,
    });
  },

  _hideModal: function (e) {
    UneekSso.UserSessions._restoreModalContent();
  },

  _submitModal: function (e) {
    e.preventDefault();

    var $form = $(e.target);
    var isSessionForm = $form.hasClass("user-sessions-form")
    if (isSessionForm) {
      UneekSso.UserSessions._connecting = true;
    }

    UneekSso.UserSessions._sendAjax("submitModal", {
      type: $form.prop("method") || "POST",
      url: $form.prop("action"),
      data: $form.serializeArray(),
      success: function (data, textStatus, xhr) {
        if (isSessionForm) {
          UneekSso.UserSessions._connecting = false;

          UneekSso.UserSessions.manageRenderServiceXhr(xhr, UneekSso.UserSessions.loginSucceeds, true);
        } else {
          UneekSso.UserSessions._loadModalContent(xhr.responseText);
        }
      },
      error: function (xhr) {
        if (isSessionForm) {
          UneekSso.UserSessions._connecting = false;
        }

        UneekSso.UserSessions._loginXhrError(xhr);
      },
    });

    return false;
  },

  _loginXhrError: function (xhr) {
    if (xhr.status === 401) {
      UneekSso.UserSessions._loadModalContent(xhr.responseText);
    } else {
      UneekSso.UserSessions.loginFailed(xhr);
    }
  },

  _loginFailedUserError: function (xhr) {
    if (UneekSso.dev)
      UneekSso.UserSessions._loadModalContent(xhr.responseText);
    else
      UneekSso.UserSessions._redirectToCasLogin();
  },

  _loginFailedServerError: function (xhr) {
    if (UneekSso.dev)
      UneekSso.UserSessions._loadModalContent(xhr.responseText);
    else
      UneekSso.UserSessions._redirectToCasLogin();
  }
}, UneekSso.UserSessions);
