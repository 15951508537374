(function ($) {
  function removeButtonRow(container, removeButton) {
    while (removeButton && (removeButton.parentNode !== container)) {
      removeButton = removeButton.parentNode;
    }
    return removeButton;
  }

  $.fn.dynamicFields = function (newFieldsContent, options_) {
    var options = $.extend({}, {
      addButtonSelector: '.add-button',
      removeButtonSelector: '.remove-button'
    }, options_);
    var container = $(this);

    container.on("click", options.addButtonSelector, function (e) {
      e.stopPropagation();
      e.preventDefault();

      var newRowIndex = new Date().getTime();
      var newRowContent = newFieldsContent.replace(/((\[)-1(\]))|((_)-1(_|"))/g, function () {
        return (arguments[2] || arguments[5]) + newRowIndex + (arguments[3] || arguments[6]);
      });
      if (options.before)
        $(options.before).before(newRowContent);
      else
        container.append(newRowContent);

      return false;
    });

    container.on("click", options.removeButtonSelector, function (e) {
      e.stopPropagation();
      e.preventDefault();

      var row = $(removeButtonRow(container[0], this));
      var rowIdInput = row.find("input").filter(function () {
        return this.name.match(/\[\d+\]\[id\]$/);
      });
      if (rowIdInput.length > 0) {
        var rowDestroyInput = $("<input>", {
          type: "hidden",
          name: rowIdInput.prop("name").replace(/\[id\]$/, "[_destroy]"),
          value: "1"
        });
        rowDestroyInput.insertAfter(rowIdInput);
        row.removeClass("d-flex").hide(); // d-flex adds an important!
      } else {
        row.remove();
      }

      return false;
    });
  };
})(jQuery);
