/*
 * Turbolinks restores the HTML of a select2 when using previous/next but does not restore Javascript.
 * The select2 appears twice on the page.
 * To prevent this, we destroy each select2 on new page loading.
 */

$(document).on("turbolinks:before-cache", function () {
  $(".select2-hidden-accessible").each(function () {
    $(this).select2("destroy");
  });
});
