import "./client/config"
import "./client/packs/v1-0/jquery3-bootstrap4"

// we want to trigger this action every time the login form is displayed (to test: load /users/sign_up, then /users/sign_in and check that $("#user_ldap_login").prop("disabled")
// TODO trigger this only when the form is loaded, but without changing the views since they are returned with XHR
$(document).on("turbolinks:load", function () {
  setTimeout(function () {
    $(document).trigger("loginFormLoaded.uneekSso");
  }, 100);
});
