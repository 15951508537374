import Create from "./create"
import Service from "./service"
import Session from "./session"

export default {
  ...Create,
  Service,
  service: Service.create,
  Session,
}
