function initFormFieldCopy($original, $copy) {
  let copy = ($original.val() === $copy.val())

  $original.on("input", () => {
    if ($original.val() === $copy.val()) {
      copy = true
    }

    if (!copy)
      return

    $copy.val($original.val())
  })

  $copy.on("input", () => {
    copy = ($original.val() === $copy.val())
  })
}

$(document).on("turbolinks:load", function () {
  $("[data-form-field-copy").each(function () {
    const original = this.form.elements[this.dataset["formFieldCopy"]]
    if (!original)
      return

    initFormFieldCopy($(original), $(this))
  })
})
