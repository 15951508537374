import Session from "./session"

export default class Service {
  static create(url) {
    if (!this._instances)
      this._instances = new Map
    if (!this._instances.has(url)) {
      this._instances.set(url, new Service(url))
    }
    return this._instances.get(url)
  }

  constructor(url) {
    this.url = url
  }

  ajax(options = {}) {
    // this is the code in a async method with classical Promise
    /*

      if (!this._currentSession) {
        this._currentSession = await this._createSession()
      }

      try {
        return await this._currentSession.ajax(options)
      } catch (e) {
        if (!this._currentSession.valid) {
          this._currentSession = await this._createSession()
          return await this._currentSession.ajax(options)
        } else {
          throw e
        }
      }
    */

    // this is the code with jQuery Deferred
    const self = this
    let deferred = $.Deferred()

    function ajax(options) {
      const request = self._currentSession.ajax(options)
      request.then(function () {
        deferred.resolveWith(request, arguments)
      }, function () {
        if (!self._currentSession.valid) {
          const sessionRequest = self._createSession()
          sessionRequest.then(function (session) {
            self._currentSession = session
            const request2 = self._currentSession.ajax(options)
            request2.then(function () {
              deferred.resolveWith(request2, arguments)
            }, function () {
              deferred.rejectWith(request2, arguments)
            })
          }, function () {
            deferred.rejectWith(sessionRequest, arguments)
          })
        } else {
          deferred.rejectWith(request, arguments)
        }
      })
    }

    if (!self._currentSession) {
      const sessionRequest = self._createSession()
      sessionRequest.then(function (session) {
        self._currentSession = session
        ajax(options)
      }, function () {
        deferred.rejectWith(sessionRequest, arguments)
      })
    } else {
      ajax(options)
    }

    return deferred
  }

  _createSession() {
    return Session.create(this.url)
  }
}
